import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Title from '../components/page-title'

const ContactPage = () => {
  return (
    <Layout>
      <SEO title='Contact'/>
      <Title title='Say hello'/>
      <form className='grid' name='arquetipo-website-contacto' method='post' action='/success' data-netlify='true' data-netlify-honeypot='bot-field' autoComplete='off'>

        <input type='hidden' name='bot-field'/>
        <input type='hidden' name='form-name' value='arquetipo-website-contacto'/>

        <fieldset className="field name" data-sal='fade' data-sal-delay='200' data-sal-duration='1000' data-sal-easing='ease'>
          <input placeholder='Alejandro De León' name='full-name' type='text' required/>
          <label htmlFor="full-name">Full Name</label>
        </fieldset>

        <fieldset className="field website" data-sal='fade' data-sal-delay='200' data-sal-duration='1000' data-sal-easing='ease'>
          <input placeholder='https://arquetipoestudio.com' name='website' type='url'/>
          <label htmlFor="website">Website</label>
        </fieldset>

        <fieldset className="field email" data-sal='fade' data-sal-delay='200' data-sal-duration='1000' data-sal-easing='ease'>
          <input placeholder='contacto@arquetipoestudio.com' name='email' type='email' required/>
          <label htmlFor="Email">Email</label>
        </fieldset>

        <fieldset className="field company" data-sal='fade' data-sal-delay='200' data-sal-duration='1000' data-sal-easing='ease'>
          <input placeholder='Arquetipo Estudio' name='company' type='text' required/>
          <label htmlFor="company">Company</label>
        </fieldset>

        <button data-sal='fade' data-sal-delay='600' data-sal-duration='1000' data-sal-easing='ease' type='submit'>Send</button>
      </form>
    </Layout>
  )
}

export default ContactPage
