import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { window } from 'browser-monads'

import Header from '../components/layout/header'
import Footer from '../components/layout/footer'
import icon from '../assets/images/arquetipo-icon.svg'
import white from '../assets/images/arquetipo-icon-white.svg'

import '../styles/custom.scss'
import "../styles/media.scss"

const Layout = ({ children }) => {
  const dark = window.matchMedia('(prefers-color-scheme:dark)');

  return (
    <>
      <Helmet>
        <link rel="icon" type="image/png" href={dark.matches ? white : icon} />
      </Helmet>
      <Header/>
        <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
