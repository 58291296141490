import React from 'react'
import { Link } from 'gatsby'
import Menu from './menu'
import Logo from '../../assets/svg/arquetipo-logo.svg'

const Header = () => (
  <header>
    <Link to="/" data-sal="fade" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="500">
      <Logo className='logo'/>
    </Link>
    <Menu/>
  </header>
)

export default Header
